import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { BackendOkResponse } from '../../shared/models/backend-response.model';
import { uuidv4 } from '../../shared/utils/uuid-utils';
import { RolesEnum, UserInfoModel } from '../models/account-info.model';
import { PermissionsEnum } from '../models/permissions.model';
import { TokenService } from './token.service';
import { UserInfoService } from './user-info.service';

interface TotpSetupInfo {
  secretKey: string;
  uri: string;
  qrCode: string;
}

interface UserLoginData {
  username: string;
  password: string;
  otp?: string;
}

interface UserLoginResponse {
  userId: number;
  token: string;
  changePwd: boolean;
  modeTFA: 'setup' | null;
}

@Injectable({ providedIn: 'root' })
export class AuthService {
  private loggedIn = new BehaviorSubject<boolean>(this.tokenService.loggedIn());
  private newApiURL = environment.newApi;

  authStatus = this.loggedIn.asObservable();
  userInfo: UserInfoModel = null;
  permissions: Record<PermissionsEnum, boolean> | {} = {};
  tempLoginData: UserLoginData;
  tmpToken: string;

  readonly homeUrl = 'smart-flow/dashboard';
  readonly sessionId = uuidv4();

  constructor(
    private tokenService: TokenService,
    private userInfoService: UserInfoService,
    private http: HttpClient,
    private logger: NGXLogger,
    private router: Router,
  ) {}

  /**
   * Checks if user has root permission
   * @return TRUE if user has root role, otherwise false
   */
  get hasRootPermission(): boolean {
    return this.userInfo.roles.includes(RolesEnum.ROOT);
  }

  getUserInfo(): Observable<UserInfoModel> {
    if (this.userInfo) {
      return of(this.userInfo);
    }

    const userId = +this.tokenService.getUserId();
    if (!userId) {
      this.router.navigateByUrl('/auth/login/');
      return of(null);
    }

    return this.userInfoService.getUserInfo(userId).pipe(
      take(1),
      tap((userInfo) => (this.userInfo = userInfo)),
      tap(
        () =>
          (this.permissions = this.preparePermissions(
            Object.values(PermissionsEnum).filter((v) => Number(v)) as number[],
          )),
      ),
      tap((userInfo) => this.logger.debug('User info:', userInfo)),
      tap(() =>
        this.logger.debug(
          'User permissions:',
          Object.keys(PermissionsEnum).filter((perm) => isNaN(Number(perm)) && this.permissions[PermissionsEnum[perm]]),
        ),
      ),
    );
  }

  setLoggedIn(value: boolean) {
    this.loggedIn.next(value);
  }

  /**
   * return TRUE if user has at least one from permissionsForCheck
   * @param permissionsForCheck
   */
  checkPermissions(permissionsForCheck: number[] | PermissionsEnum[]): boolean {
    return permissionsForCheck.some((perm) => this.permissions[perm]);
  }

  /**
   *
   * @param requiredPermissions
   */
  preparePermissions = (requiredPermissions: PermissionsEnum[]): Record<PermissionsEnum, boolean> | {} => {
    return requiredPermissions.reduce((acc, perm) => {
      const allowed = this.userInfo.permissions.includes(perm);
      return {
        ...acc,
        ...(allowed && { [perm]: allowed }),
      };
    }, {});
  };

  storeTempCred(tempLoginData: UserLoginData) {
    this.tempLoginData = tempLoginData;
  }

  login({ username, password, otp }: UserLoginData): Observable<UserLoginResponse> {
    const url = `${this.newApiURL}/login`;

    return this.http.post<UserLoginResponse>(url, {
      username: username,
      password: password,
      ...(otp && { otp }),
    });
  }

  applyLogin(loginRes) {
    this.tokenService.setUserData(loginRes);
    this.setLoggedIn(true);
    const startUrl = sessionStorage.getItem('startUrl');
    this.router.navigateByUrl(startUrl ?? this.homeUrl);
    sessionStorage.removeItem('startUrl');
    const bc = new BroadcastChannel('reLogin_channel');
    bc.postMessage({ type: 'refresh', sessionId: this.sessionId });
  }

  clearUserData() {
    this.tokenService.remove();
    this.setLoggedIn(false);
  }

  getTfaTotpSetupInfo(): Observable<TotpSetupInfo> {
    const url = `${this.newApiURL}/tfa/totp`;

    return this.http.get<TotpSetupInfo>(url, { headers: { Authorization: this.tmpToken } });
  }

  registerMfa(provider: 'email' | 'TOTP') {
    const url = `${this.newApiURL}/tfa/register`;

    return this.http
      .post(url, { provider }, { headers: { Authorization: this.tmpToken } })
      .pipe(tap(() => (this.tmpToken = null)));
  }

  logout(): Observable<BackendOkResponse> {
    if (!this.tokenService.getAccessToken()) {
      this.clearUserData();
      return of({});
    }
    const url = `${this.newApiURL}/logout`;

    return this.http.post<BackendOkResponse>(url, {}).pipe(
      tap(() => this.clearUserData()),
      catchError((_) => {
        this.clearUserData();
        return of({});
      }),
    );
  }
}
