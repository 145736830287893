/** @deprecated */
export class AccountInfoModelOld {
  ID: number = null;
  fullName: string = null;
  emailAddress: string = null;
  phone: string = null;
  timezoneID: number = null;
  location: string = null;
  company: string = null;
  department: string = null;
}

export interface UserInfoModel {
  id: number;
  fullName: string;
  insertDateTime?: string;
  emailAddress: string;
  operatorID?: number;
  companies: number[];
  brands: number[];
  roles?: number[];
  categories: number[];
  permissions: number[];
  statusID?: 1 | 2;
  useTFA: boolean;
}

export enum UserStatuses {
  Deactivated = 0,
  Active = 1,
}

export enum RolesEnum {
  ROOT = 1,
  ADMINISTRATOR = 2,
  MANAGEMENT = 3,
  AGENT = 4,
}

export enum RoleNames {
  ADMINISTRATOR = 'Administrator',
  MANAGEMENT = 'Management',
  AGENT = 'Agent',
}

export interface RolePermissionsModel {
  roleID: RolesEnum;
  roleName: RoleNames;
  permissions: number[];
}
