import { Injectable } from '@angular/core';

@Injectable()
export class TokenService {
  getUsername() {
    return localStorage.getItem('username');
  }

  getUserId() {
    return localStorage.getItem('userId');
  }

  remove() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('username');
    localStorage.removeItem('userId');
  }

  tokenExpired(token: string) {
    if (!token || token === 'undefined') {
      return true;
    }
    const expiry = JSON.parse(atob(token?.split('.')[1])).exp;
    return Math.floor(new Date().getTime() / 1000) >= expiry;
  }

  isValid() {
    const token = this.getAccessToken();
    return !!token && !this.tokenExpired(token);
  }

  loggedIn() {
    return this.isValid();
  }

  getAccessToken() {
    return localStorage.getItem('accessToken');
  }

  setUserData(loginRes) {
    localStorage.setItem('accessToken', loginRes.token);
    localStorage.setItem('userId', loginRes.userId);
  }
}
