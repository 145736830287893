import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { BackendOkResponse } from '../../shared/models/backend-response.model';
import { DictionaryModel } from '../../shared/models/dropdown.model';
import { CacheService } from '../../shared/services/cache.service';
import { AccountInfoModelOld, UserInfoModel } from '../models/account-info.model';

@Injectable({
  providedIn: 'root',
})
export abstract class UserInfoService {
  apiURL: string = environment.api;
  newApiURL: string = environment.newApi;

  constructor(
    private httpClient: HttpClient,
    private cacheService: CacheService,
  ) {}

  getUserInfo(id: number) {
    return this.httpClient.get<UserInfoModel>(`${this.newApiURL}/users/${id}`);
  }

  saveUserInfo(userInfo: UserInfoModel): Observable<BackendOkResponse> {
    let url = `${this.newApiURL}/users`;
    if (userInfo.id) {
      url = `${url}/${userInfo.id}`;
    } else {
      userInfo.id = undefined;
    }

    return this.httpClient[userInfo.id ? 'put' : 'post'](url, userInfo);
  }

  getUsers(): Observable<{ items: UserInfoModel[] }> {
    const url = this.newApiURL + '/users';
    return this.cacheService.getCachedData<{ items: UserInfoModel[] }>('users', url);
  }

  quickSearch(userSearchKeyword: string) {
    const url = `${this.newApiURL}/users/quicksearch/by-filters?userSearchKeyword=${userSearchKeyword}`;

    return this.httpClient.get<{ items: DictionaryModel[] }>(url);
  }

  forgotPassword(email: string) {
    const url = `${this.newApiURL}/users/resetPassword?email=${email}`;

    return this.httpClient.post<any>(url, { email });
  }

  resetPasswordById(id: number) {
    const url = `${this.newApiURL}/users/${id}/resetPassword`;

    return this.httpClient.post(url, { id });
  }

  updatePassword(userID: number, oldPassword: string, newPassword: string): Observable<any> {
    const url = `${this.newApiURL}/users/${userID}/updatePassword`;

    return this.httpClient.post<any>(url, { oldPassword, newPassword });
  }

  /** @deprecated */
  updateAccountInfo(accountInfo: AccountInfoModelOld): Observable<any> {
    const url = this.apiURL + 'updateaccount';

    const content = JSON.stringify({
      updateinfo: {
        ID: accountInfo.ID != null ? accountInfo.ID : null,
        fullName: accountInfo.fullName != null ? accountInfo.fullName : null,
        emailAddress: accountInfo.emailAddress != null ? accountInfo.emailAddress : null,
        phone: accountInfo.phone != null ? accountInfo.phone : null,
        timezoneID: accountInfo.timezoneID != null ? accountInfo.timezoneID : 0,
        location: accountInfo.location != null ? accountInfo.location : null,
        company: accountInfo.company != null ? accountInfo.company : null,
        department: accountInfo.department != null ? accountInfo.department : null,
      },
    });

    const body = new HttpParams().set('updateinfo', content);

    return this.httpClient.post<any>(url, body);
  }

  // TODO: will be remove after migrate all consumer to dictionary service
  /** @deprecated */
  public getTimezones(): Observable<any> {
    const url = this.apiURL + 'gettimezones';

    return this.httpClient.get<any>(url);
  }

  /** @deprecated */
  getCompanies(userId: string): Observable<any> {
    const filters = `filters={"filters":{"userID":"${userId}"}}`;
    return this.httpClient.post<any>(this.apiURL + 'getcompaniesperuserid', filters);
  }

  /** @deprecated */
  getUsersData(username: string): Observable<any> {
    const url = this.apiURL + 'searchadminuser';
    const content = JSON.stringify({
      username: username,
    });
    const body = new HttpParams().set('userName', content);
    return this.httpClient.post<any>(url, body);
  }

  /** @deprecated */
  brandsperuserid(userId: string) {
    const filters = `filters={"filters":{"userID":"${userId}"}}`;
    return this.httpClient.post<any>(this.apiURL + 'brandsperuserid', filters);
  }
  /** @deprecated */
  public categories(): Observable<any> {
    const url = this.apiURL + 'categories';
    return this.httpClient.get<any>(url);
  }
  /** @deprecated */
  public roles(): Observable<any> {
    const url = this.apiURL + `${this.newApiURL}/permissionroles`;
    return this.httpClient.get<any>(url);
  }

  /** @deprecated */
  saveAdminAccountInfo(saveinfo: any): Observable<any> {
    const url = `${this.apiURL}/saveadminaccountinfo`;

    const content = JSON.stringify({
      updateinfo: {
        userID: saveinfo.userID != null ? saveinfo.userID : null,
        fullName: saveinfo.fullName != null ? saveinfo.fullName : null,
        emailAddress: saveinfo.emailAddress != null ? saveinfo.emailAddress : null,
        phone: saveinfo.phone != null ? saveinfo.phone : null,
        location: saveinfo.location != null ? saveinfo.location : null,
        company: saveinfo.company != null ? saveinfo.company : null,
        department: saveinfo.department != null ? saveinfo.department : null,
      },
    });
    const body = new HttpParams().set('updateinfo', content);
    // console.log('body', body);

    return this.httpClient.post<any>(url, body);
  }

  /** @deprecated */
  getEventLog(username: string, fromDate: Date, toDate: Date, pageNumber: number) {
    const url = this.apiURL + 'geteventlog';
    const content = JSON.stringify({
      filters: {
        fromDate: fromDate ? fromDate : null,
        toDate: toDate ? toDate : null,
        username: username ? username : null,
        objectName: null,
        eventname: null,
      },
      pageSize: 10,
      pageNumber: pageNumber,
    });

    const body = new HttpParams().set('filters', content);
    // console.log('body', body);

    return this.httpClient.post<any>(url, body);
  }
  /** @deprecated */
  changeUserActiveStatus(userID: number, statusID: number): Observable<any> {
    const filters = `statusinfo={"userID":"${userID}","statusID":"${statusID}"}`;
    return this.httpClient.post<any>(this.apiURL + 'changeuseractivestatus', filters);
  }
  /** @deprecated */
  updateUserCompany(userID: number, companiesID: number[]) {
    const content = JSON.stringify({
      userID: userID,
      companiesID: companiesID,
    });
    const body = new HttpParams().set('updateinfo', content);
    return this.httpClient.post<any>(this.apiURL + 'updateusercompany', body);
  }
  /** @deprecated */
  updateUserBrand(userID: number, brandsID: number[]) {
    const content = JSON.stringify({
      userID: userID,
      brandID: brandsID,
    });
    const body = new HttpParams().set('updateinfo', content);
    return this.httpClient.post<any>(this.apiURL + 'updateuserbrand', body);
  }
  /** @deprecated */
  updateUserCategory(userID: number, categoriesID: number[]) {
    const content = JSON.stringify({
      userID: userID,
      categoryID: categoriesID,
    });
    const body = new HttpParams().set('updateinfo', content);
    return this.httpClient.post<any>(this.apiURL + 'updateusercategory', body);
  }
  /** @deprecated */
  adminuserpermissions(userID: number) {
    const content = JSON.stringify({
      userID: userID,
    });
    const body = new HttpParams().set('userinfo', content);
    return this.httpClient.post<any>(this.apiURL + 'adminuserpermissions', body);
  }

  /** @deprecated */
  getSinglePermissionSettings(): Observable<any> {
    const url = this.apiURL + 'getsinglepermissionsettings';
    return this.httpClient.get<any>(url);
  }
  /** @deprecated */
  savesinglepermissionperuser(param: any) {
    const body = new HttpParams().set('permissions', param);
    return this.httpClient.post<any>(this.apiURL + 'savesinglepermissionperuser', body);
  }
  /** @deprecated */
  updateUserRole(userID: number, rolesID: number[]) {
    const content = JSON.stringify({
      userID: userID,
      roleID: rolesID,
    });
    const body = new HttpParams().set('updateinfo', content);
    return this.httpClient.post<any>(this.apiURL + 'updateuserpermissiongroup', body);
  }
}
